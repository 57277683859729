import React from "react"

export default function Header({ title, description }) {
  return (
    <div className="flex flex-col items-center justify-center h-40 md:h-32 bg-primary">
      <h1 className="text-4xl md:text-6xl text-secondary font-serif w-11/12 md:w-full mx-auto text-center max-w-2xl">
        {title}
      </h1>
      {description && <h2 className="text-sm">{description}</h2>}
    </div>
  )
}
