import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Header from "../components/section/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
export default function Blogs({ data: { blogs }, location }) {
  return (
    <Layout>
      <Seo title="Latest Blogs" pathname={location.pathname} />
      <Header title="Latest Blogs" />
      <section className="max-w-7xl mx-auto w-11/12 py-6 md:py-12 2xl:w-full flex flex-col gap-10">
        {/* <div className="flex flex-col gap-4 items-center">
          <h1 className="text-4xl font-serif text-secondary">Latest Blogs</h1>
        </div> */}

        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {blogs.nodes.map((blog, key) => {
            const data = blog.frontmatter
            return (
              <Link to={`/blogs${blog.fields.slug}`}>
                <div key={key} className="space-y-6">
                  {data?.cover_image?.childImageSharp ? (
                    <GatsbyImage
                      image={data.cover_image.childImageSharp.gatsbyImageData}
                      className="h-56 w-full object-cover"
                      alt={data.title}
                    />
                  ) : (
                    <img
                      src={data.cover_image}
                      className="h-56 w-full object-cover"
                      alt={data.title}
                    />
                  )}
                  <div className="space-y-2">
                    <h3 className="text-lg md:text-xl font-bold truncate">
                      {data.title}
                    </h3>
                    <p className="text-sm font-light multi-line">
                      {data.excerpt}
                    </p>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    blogs: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "blogs" } }
        frontmatter: { publish: { eq: true } }
      }
      sort: { fields: frontmatter___publish_date, order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          excerpt
          cover_image
        }
      }
    }
  }
`
